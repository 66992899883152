import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  AbstractAuthService,
  AbstractEntityTypeService,
  ApiGenericService,
  ArrayUtilityService,
  BaseActionKey,
  DynamicDialogService,
  DynamicFormFieldChangeOutput,
  EntityType,
  EntityTypeOperation,
  ExecutedAction,
  ExecutedActionBehaviour,
  Filter,
  FilterExpressions,
  FilterGroup,
  FilterOperations,
  GenericRelation,
  HttpVerb,
  InterceptorConfig,
  NotificationsService,
  ObjectsUtilityService,
  PageContextService,
  TabConfig,
  User,
  UtilityService,
  ViewMode,
} from '@prg/prg-core-lib';
import { Department } from '../../../../../Core/models/department.model';
import { DepartmentService } from '../../../../../Core/services/department-service/department.service';
import { ResourceService } from '../../../../../resources/services/resource.service';
import { TicketFormComponent } from '../../../../../tickets/components/ticket-list/components/ticket-form/ticket-form.component';
import { Ticket } from '../../../../../tickets/models/ticket.model';
import { UserAvailabilitiesService } from '../../../../../user-availabilities/services/user-availabilities.service';
import { ContractDateRangeVerifierStates } from '../../../../../work-orders/models/contract-data-range-verifier-states-model';
import { WorkOrder } from '../../../../models/work-order-model';
import { WorkOrderOperationsComponent } from '../../../work-order-operations/work-order-operations.component';
import { WorkOrderRecurringComponent } from '../../../work-order-recurring/work-order-recurring.component';

@Component({
  selector: 'prg-work-order-element',
  templateUrl: './work-order-modal.component.html',
  styleUrls: ['./work-order-modal.component.scss'],
  providers: [PageContextService],
})
export class WorkOrderModalComponent implements OnInit {
  @Input() element: WorkOrder = null;
  @Input() entityType: EntityType = null;
  @Input() displayWorkOrderPanel: boolean = false;
  private _viewMode: ViewMode = ViewMode.Edit;

  @Input() set viewMode(viewMode: ViewMode) {
    this._viewMode = viewMode;
  }

  get viewMode(): ViewMode {
    return this._viewMode;
  }

  @Input() haveLoadedChanges: boolean = false;
  @Input() hasOperations: boolean = null;
  @Input() hasRecurring: boolean = null;

  public formTabs: TabConfig[] = null;

  public isDepartmentManager: boolean = false;

  public showTabRelations: boolean = true;
  public entityTypeCopy: EntityType;

  @Output() executedAction = new EventEmitter<ExecutedAction>();
  @Output() fieldChangeOutput =
    new EventEmitter<DynamicFormFieldChangeOutput>();

  public actionBehaviour: ExecutedActionBehaviour = new ExecutedActionBehaviour(
    { redirectToList: false, reloadData: false, changeViewModeToRead: false }
  );

  public workOrderCopy: WorkOrder;

  private optionsDepartment: Department[] = [];

  public user: User;

  constructor(
    private entityTypeService: AbstractEntityTypeService,
    private utilityService: UtilityService,
    public dialogService: DynamicDialogService,
    private objectsUtilityService: ObjectsUtilityService,
    private userAvailabilitiesService: UserAvailabilitiesService,
    private cd: ChangeDetectorRef,
    private apiGenericService: ApiGenericService,
    private translateService: TranslateService,
    private departmentService: DepartmentService,
    private arrayUtilityService: ArrayUtilityService,
    private authService: AbstractAuthService,
    private resourceService: ResourceService,
    private notificationsService: NotificationsService
  ) {
    this.user = this.authService.getLoggedUser();

    if (this.departmentService.currentUserIsDepartmentManager()) {
      this.isDepartmentManager = true;
    }
  }

  async ngOnInit() {
    this.entityTypeCopy = this.objectsUtilityService.clone(this.entityType);
    this.workOrderCopy = this.objectsUtilityService.clone(this.element);
    const checklistsGuiSettingsProperty = this.entityTypeCopy.properties.find(
      (p) => p.name === 'checklists'
    );

    const checklists = (
      await this.entityTypeService.getEntityTypeElements('checklist')
    ).entity;
    checklistsGuiSettingsProperty.guiSettings =
      this.utilityService.updateGuiSettingsProperty(
        checklistsGuiSettingsProperty.guiSettings,
        'options',
        checklists
      );
    if (this.isDepartmentManager) {
      this.showTabRelations = false;
      this.updateGuiSettingsForDepartmentManager(this.element);
    }

    if (this.element != null) {
      if (this.element.id != null) {
        if (this.hasOperations == null) {
          this.hasOperations = true;
        }
        if (this.hasRecurring == null) {
          this.hasRecurring = false;
        }
        await this.getAndSetAvailableUsersForDates(
          new Date(this.element.plannedStartDate),
          new Date(this.element.plannedFinishDate),
          null,
          this.element?.id,
          this.element?.departmentId
        );
      } else {
        if (this.hasOperations == null) {
          this.hasOperations = true;
        }
        if (this.hasRecurring == null) {
          this.hasRecurring = true;
        }
        await this.getAndSetAvailableUsersForDates(
          this.element.plannedStartDate,
          this.element.plannedFinishDate,
          null,
          null,
          this.element?.departmentId
        );
      }
    }
    this.setDynamicFormTabs(this.hasOperations, this.hasRecurring);
    this.haveLoadedChanges = true;
  }

  public async onExecutedAction(action: ExecutedAction) {
    if (
      action.baseAction.key === BaseActionKey.Cancel &&
      this.element?.id != null
    ) {
      this.element = this.objectsUtilityService.clone(this.workOrderCopy);
      await this.getAndSetAvailableUsersForDates(
        new Date(this.element.plannedStartDate),
        new Date(this.element.plannedFinishDate),
        null,
        this.element?.id,
        this.element?.departmentId
      );
      return;
    }

    if (
      action.baseAction.key === BaseActionKey.Edit ||
      action.baseAction.key === BaseActionKey.Cancel
    ) {
      return;
    }
    if (action.baseAction.key === 'create') {
      Object.assign(this.element, action.actionResult.entity);
      let contractState =
        await this.resourceService.doesContractIncludeWorkOrderDates(
          this.element
        );
      let messagePath = null;
      if (contractState == ContractDateRangeVerifierStates.WithoutContract) {
        messagePath = 'messages.create-work-order-without-contract';
      } else if (
        contractState == ContractDateRangeVerifierStates.InvalidContract
      ) {
        messagePath = 'messages.create-work-order-invalid-contract';
      }

      if (
        messagePath == null ||
        (await this.notificationsService.prgConfirmationService(messagePath))
      ) {
        const entityResult = await this.apiGenericService.post(
          this.utilityService.createUrlToRequest(
            'workorder',
            null,
            new EntityTypeOperation({ name: 'create', httpVerb: HttpVerb.Post })
          ),
          this.element,
          new InterceptorConfig({
            apiRequest: true,
          })
        );

        if (entityResult) {
          this.element = entityResult.entity;
          action.actionResult.entity = this.element;
        }
      }
    }

    if (action.baseAction.key === 'create') {
      Object.assign(this.element, action.actionResult.entity);
      let contractState =
        await this.resourceService.doesContractIncludeWorkOrderDates(
          this.element
        );
      let messagePath = null;
      if (contractState == ContractDateRangeVerifierStates.WithoutContract) {
        messagePath = 'messages.create-work-order-without-contract';
      } else if (
        contractState == ContractDateRangeVerifierStates.InvalidContract
      ) {
        messagePath = 'messages.create-work-order-invalid-contract';
      }

      if (
        messagePath == null ||
        (await this.notificationsService.prgConfirmationService(messagePath))
      ) {
        const entityResult = await this.apiGenericService.post(
          this.utilityService.createUrlToRequest(
            'workorder',
            null,
            new EntityTypeOperation({ name: 'create', httpVerb: HttpVerb.Post })
          ),
          this.element,
          new InterceptorConfig({
            apiRequest: true,
          })
        );

        if (entityResult) {
          this.element = entityResult.entity;
          action.actionResult.entity = this.element;
        }
      }
    }
    if (action.baseAction.key === 'create') {
      Object.assign(this.element, action.actionResult.entity);
      let contractState =
        await this.resourceService.doesContractIncludeWorkOrderDates(
          this.element
        );
      let messagePath = null;
      if (contractState == ContractDateRangeVerifierStates.WithoutContract) {
        messagePath = 'messages.create-work-order-without-contract';
      } else if (
        contractState == ContractDateRangeVerifierStates.InvalidContract
      ) {
        messagePath = 'messages.create-work-order-invalid-contract';
      }

      if (
        messagePath == null ||
        (await this.notificationsService.prgConfirmationService(messagePath))
      ) {
        const entityResult = await this.apiGenericService.post(
          this.utilityService.createUrlToRequest(
            'workorder',
            null,
            new EntityTypeOperation({ name: 'create', httpVerb: HttpVerb.Post })
          ),
          this.element,
          new InterceptorConfig({
            apiRequest: true,
          })
        );

        if (entityResult) {
          this.element = entityResult.entity;
          action.actionResult.entity = this.element;
        }
      }
    }

    if (action.baseAction.key === 'update') {
      Object.assign(this.element, action.actionResult.entity);
      let contractState =
        await this.resourceService.doesContractIncludeWorkOrderDates(
          this.element
        );
      let messagePath = null;
      if (contractState == ContractDateRangeVerifierStates.WithoutContract) {
        messagePath = 'messages.create-work-order-without-contract';
      } else if (
        contractState == ContractDateRangeVerifierStates.InvalidContract
      ) {
        messagePath = 'messages.create-work-order-invalid-contract';
      }

      if (
        messagePath == null ||
        (await this.notificationsService.prgConfirmationService(messagePath))
      ) {
        const entityResult = await this.apiGenericService.put(
          this.utilityService.createUrlToRequest(
            'workorder',
            null,
            new EntityTypeOperation({ name: 'update', httpVerb: HttpVerb.Put })
          ),
          this.element,
          this.element.id,
          new InterceptorConfig({
            apiRequest: true,
          })
        );

        if (entityResult) {
          this.element = entityResult.entity;
          action.actionResult.entity = this.element;
        }
      }
    }
    if (action.baseAction.key === 'createworkorderfromanother') {
      this.element = new WorkOrder({
        genericRelation: new GenericRelation({
          sourceEntityType: 'WorkOrder',
          targetEntityType: 'WorkOrder',
          sourceId: this.element.id,
        }),
        resourceId: this.element.resourceId,
        name: this.element.name,
        description: this.element.description,
        workOrderTypeId: this.element.workOrderTypeId,
        recurrence: null,
        isRecurring: false,
      });
      this.viewMode = ViewMode.Edit;
      this.hasRecurring = true;
      await this.ngOnInit();
      return;
    }
    if (action.baseAction.key === 'createticketfromworkorder') {
      this.openDialogTicket(this.element);
      return;
    }

    this.executedAction.emit(action);
  }

  public onFieldChangeOutputted = this.utilityService.debounce(
    async (event: DynamicFormFieldChangeOutput) => {
      if (
        (event.field === 'plannedStartDate' ||
          event.field === 'plannedFinishDate') &&
        event.formEntity.plannedStartDate != null &&
        event.formEntity.plannedFinishDate != null
      ) {
        await this.getAndSetAvailableUsersForDates(
          new Date(event.formEntity.plannedStartDate),
          new Date(event.formEntity.plannedFinishDate),
          event.formEntity,
          event?.formEntity?.id,
          event?.formEntity?.departmentId
        );

        this.viewMode = ViewMode.Read;
        this.cd.detectChanges();
        this.viewMode = ViewMode.Edit;
        this.cd.detectChanges();
      }
      if (event.field === 'departmentId') {
        await this.getAndSetAvailableUsersForDates(
          new Date(event.formEntity.plannedStartDate),
          new Date(event.formEntity.plannedFinishDate),
          event.formEntity,
          event?.formEntity?.id,
          event?.value
        );

        this.viewMode = ViewMode.Read;
        this.cd.detectChanges();
        this.viewMode = ViewMode.Edit;
        this.cd.detectChanges();
      }

      this.fieldChangeOutput.emit(event);
    },
    500
  );

  private setDynamicFormTabs(hasOperations: boolean, hasRecurring: boolean) {
    if (!hasOperations && !hasRecurring) {
      this.formTabs = null;
      return;
    }
    this.formTabs = [];
    if (hasOperations) {
      this.formTabs.push(
        new TabConfig({
          icon: 'pi pi-check-square',
          key: 'other-operations',
          translationPath: 'pages.work-orders.tabs',
          componentData: {
            component: WorkOrderOperationsComponent,
            // inputs: {
            //   workOrder: this.element,
            // },
          },
        })
      );
    }

    if (hasRecurring) {
      this.formTabs.push(
        new TabConfig({
          icon: 'pi pi-calendar',
          key: 'recurring',
          translationPath: 'pages.user-availabilities.tabs',
          componentData: {
            component: WorkOrderRecurringComponent,
            /* inputs: {
              workOrder: this.element,
            },*/
          },
        })
      );
    }
  }

  private async getAndSetAvailableUsersForDates(
    plannedStartDate: Date,
    plannedFinishDate: Date,
    formEntity: WorkOrder = null,
    workOrderId: string = null,
    departmentId: string = null
  ): Promise<void> {
    const availableUsers =
      await this.userAvailabilitiesService.getAvailableUsersByDateIntervalAsync(
        plannedStartDate,
        plannedFinishDate,
        workOrderId,
        departmentId
      );

    const availableWorkOrderUsers: any[] = availableUsers.map((a) => {
      return {
        workOrderId: this.element.id,
        userId: a.id,
        user: a,
        disabled: a['disabled'],
      };
    });

    if (
      this.element.workOrderUsers != null &&
      this.element.workOrderUsers.length &&
      availableWorkOrderUsers != null &&
      availableWorkOrderUsers.length > 0
    ) {
      const usersIdToRemoveFromList: string[] = [];
      this.element.workOrderUsers.forEach((workOrderUser) => {
        const index = availableWorkOrderUsers.findIndex(
          (u) => u.userId === workOrderUser.userId
        );
        if (index != -1) {
          const disabled = availableWorkOrderUsers[index].disabled;
          availableWorkOrderUsers[index] = workOrderUser;
          availableWorkOrderUsers[index].disabled = disabled;
        } else {
          usersIdToRemoveFromList.push(workOrderUser.userId);
        }
      });
      if (usersIdToRemoveFromList.length > 0) {
        usersIdToRemoveFromList.forEach((x) => {
          const aux2 = formEntity.workOrderUsers.findIndex(
            (wou) => wou.userId == x
          );
          if (aux2 != -1) {
            formEntity.workOrderUsers.splice(aux2, 1);
          }
        });
      }
    }

    if (
      formEntity?.workOrderUsers &&
      (availableWorkOrderUsers == null || availableWorkOrderUsers.length == 0)
    ) {
      formEntity.workOrderUsers = null;
    }

    const workOrderUsersGuiSettingsProperty =
      this.entityTypeCopy.properties.find((p) => p.name === 'workOrderUsers');

    const workOrderUsersGuiSettings = JSON.parse(
      workOrderUsersGuiSettingsProperty.guiSettings
    );

    workOrderUsersGuiSettings['options'] = availableWorkOrderUsers;

    workOrderUsersGuiSettingsProperty.guiSettings = JSON.stringify(
      workOrderUsersGuiSettings
    );

    this.entityTypeCopy = this.objectsUtilityService.clone(this.entityTypeCopy);
    if (formEntity != null) {
      this.element = formEntity;
    }
  }

  public openDialogTicket(workOrder: WorkOrder) {
    const popupTitle: string = this.translateService.instant(
      'pages.tickets.components.ticket-form.new-ticket.label'
    );

    //TODO:Fin out why we to declare generic relation as any to make create button enable on ticket form
    const newTicket: Ticket = new Ticket({
      genericRelation: {
        sourceId: workOrder.id,
        sourceEntityType: 'WorkOrder',
        targetEntityType: 'Ticket',
      },
      rootResourceId:
        workOrder.resourceId != null ? workOrder.resourceId : null,
      name: workOrder.name,
      isPublic: false,
      description: workOrder.description,
    });

    this.dialogService.openDialog(TicketFormComponent, {
      header: popupTitle,
      autoZIndex: false,
      modal: true,
      draggable: false,
      styleClass: 'container-ticket-dialog-form',
      style: { height: '90vh', 'min-height': '90vh', 'max-width': '1100px' },
      data: {
        entity: newTicket,
        showNotes: true,
      },
    });
  }

  private updateGuiSettingsForDepartmentManager(workOrder: WorkOrder): void {
    const resourceIdGuiSettingsProperty = this.entityTypeCopy.properties.find(
      (p) => p.name === 'resourceId'
    );
    resourceIdGuiSettingsProperty.guiSettings =
      this.utilityService.updateGuiSettingsProperty(
        resourceIdGuiSettingsProperty.guiSettings,
        'fieldDependencies',
        [
          {
            ignoreNullValueOnDependency: false,
            fieldName: 'departmentId',
            propertyName: 'Departments[Id]',
          },
        ]
      );

    const departmentGuiSettingsProperty = this.entityTypeCopy.properties.find(
      (p) => p.name === 'departmentId'
    );
    const mapUpdateGuiSettingsForDepartment = new Map<string, any>([
      ['validators', ['Validators.required']],
    ]);
    const departments: Department[] = this.arrayUtilityService.clone(
      this.departmentService.getUserDepartmentsValue()
    );
    if (workOrder.id == null) {
      this.optionsDepartment = departments.filter(
        (x) => x.canCreateWorkOrder == true
      );
      this.optionsDepartment.forEach((x) => {
        x.userProfiles = null;
        x.resources = null;
      });
      if (this.optionsDepartment.length == 1) {
        workOrder.departmentId = this.optionsDepartment[0].id;
      }

      mapUpdateGuiSettingsForDepartment.set(
        'filterGroup',
        new FilterGroup({
          filterCollections: [
            new Filter({
              propertyName: 'CanCreateWorkOrder',
              filterOperation: FilterOperations.EqualTo,
              value: 'true',
              startGroup: true,
              filterExpression: FilterExpressions.And,
            }),
          ],
        })
      );
    }
    departmentGuiSettingsProperty.guiSettings =
      this.utilityService.updateGuiSettingsMultipleProperties(
        departmentGuiSettingsProperty.guiSettings,
        mapUpdateGuiSettingsForDepartment
      );

    if (workOrder.id && workOrder.createdBy != this.user.id) {
      this.updateGuiSettingsForDates(workOrder);
    }
  }

  public updateGuiSettingsForDates(workOrder: WorkOrder) {
    const departments: Department[] = this.arrayUtilityService.clone(
      this.departmentService.getUserDepartmentsValue()
    );

    const departmentSelected = departments.find(
      (x) => x.id == workOrder.departmentId
    );
    if (
      departmentSelected == null ||
      !departmentSelected.canEditWorkOrderDates
    ) {
      const plannedStartDateGuiSettingsProperty =
        this.entityTypeCopy.properties.find(
          (p) => p.name === 'plannedStartDate'
        );
      const plannedFinishDateGuiSettingsProperty =
        this.entityTypeCopy.properties.find(
          (p) => p.name === 'plannedFinishDate'
        );

      plannedStartDateGuiSettingsProperty.guiSettings =
        this.utilityService.updateGuiSettingsProperty(
          plannedStartDateGuiSettingsProperty.guiSettings,
          'enableExpression',
          'false'
        );
      plannedFinishDateGuiSettingsProperty.guiSettings =
        this.utilityService.updateGuiSettingsProperty(
          plannedFinishDateGuiSettingsProperty.guiSettings,
          'enableExpression',
          'false'
        );
    }
  }
}
