import { Component, forwardRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  Calendar,
  CalendarApi,
  CalendarOptions,
  DateSelectArg,
  DatesSetArg,
  EventClickArg,
  EventDropArg,
  EventInput,
} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, {
  EventResizeDoneArg,
} from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import { TranslateService } from '@ngx-translate/core';
import {
  AbstractAuthService,
  AbstractEntityTypeService,
  AbstractLookupTableService,
  ArrayUtilityService,
  AuthGuard,
  BaseActionKey,
  EntityType,
  ExecutedAction,
  ExecutedActionBehaviour,
  Filter,
  FilterGroup,
  FilterOperations,
  GenericRelation,
  NotificationsService,
  PageContextService,
  PrgError,
  PrgFilterMenuItem,
  PrgFilterMenuItemChild,
  User,
  UtilityService,
  ViewMode,
} from '@prg/prg-core-lib';
import * as dayjs from 'dayjs';
import { TreeNode } from 'primeng/api';
import { CalendarLegend } from 'src/app/user-availabilities/models/user-availability.model';
import tippy from 'tippy.js';
import { Department } from '../../../Core/models/department.model';
import { DepartmentService } from '../../../Core/services/department-service/department.service';
import { ResourceService } from '../../../resources/services/resource.service';
import { UserProfileService } from '../../../users/services/user-profile-service/user-profile.service';
import { ContractDateRangeVerifierStates } from '../../models/contract-data-range-verifier-states-model';
import { WorkOrder } from '../../models/work-order-model';
import { WorkOrdersService } from '../../services/work-orders.service';
import { CalendarFilters } from './calendar-filters';

@Component({
  selector: 'app-work-orders-calendar',
  templateUrl: './work-orders-calendar.page.html',
  styleUrls: ['./work-orders-calendar.page.scss'],
})
export class WorkOrdersCalendarPage implements OnInit {
  public readonly WORK_ORDERS_ROOT_ENTITY_TYPE = 'Client';
  public readonly WORK_ORDERS_ENTITY_TYPE = 'WorkOrder';
  private workOrderStatesTranslation = this.translateService.instant(
    'lookup-tables.workorderstates.items'
  );
  public selectedElement: any = {};
  public selectedEntityType: EntityType;
  public workOrderViewMode: ViewMode = ViewMode.Edit;
  public treeNodes: TreeNode[];
  public selectedNode: TreeNode;
  private selectedNodeResourceIds: string[];
  private workOrders: WorkOrder[] = [];
  private calendarViewStartDate: Date;
  private calendarViewEndDate: Date;
  private calendarApi: CalendarApi;
  private WORK_ORDER_WITHOUT_USERS_APPEND: string = '*';
  public selectedDates: Date[] = [];
  public displayWorkOrderModal: boolean = false;
  public workOrderId: string;
  public displayWorkOrderPanel: boolean = false;
  /*  public hasOperations: boolean = false;
    public hasRecurring: boolean = false;*/

  public calendarLegends: CalendarLegend[];
  public workOrderStatesSelected: string[] = [
    'workorderstates.ongoing',
    'workorderstates.scheduled',
    'workorderstates.finished',
  ];

  public calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
    initialView: 'dayGridMonth',
    headerToolbar: {
      left: 'title',
      center: 'prev,today,next',
      /*right: 'dayGridMonth,dayGridWeek,dayGridDay',*/
      right: 'dayGridMonth,timeGridWeek,timeGridDay',
    },
    weekends: true,
    editable: true,
    eventDurationEditable: true,
    eventResizableFromStart: true,
    selectable: false,
    unselectAuto: false,
    dayMaxEvents: true,
    displayEventEnd: true,
    scrollTime: '08:00:00',
    height: '100%',

    eventTimeFormat: {
      // like '14:30:00'
      hour: '2-digit',
      minute: '2-digit',
    },
    locale: this.translateService.instant('app.locale'),
    buttonText: this.translateService.instant('components.calendar.buttons'),
    allDayText: this.translateService.instant('components.calendar.all-day'),
    events: [],
    select: this.onCalendarDatesSelected.bind(this),
    unselect: this.onCalendarDatesUnSelected.bind(this),
    eventDrop: this.onCalendarEventDrop.bind(this),
    eventResize: this.onCalendarEventResizeStopped.bind(this),
    eventClick: this.onCalendarEventClicked.bind(this),
    datesSet: this.onCalendarDatesSetted.bind(this),
  };

  public actionBehaviour: ExecutedActionBehaviour = new ExecutedActionBehaviour(
    { redirectToList: false, reloadData: false, changeViewModeToRead: false }
  );

  public calendarType: string = 'parks';

  public calendarTypeOptions: { value: string; label: string }[] = [
    {
      value: 'parks',
      label: 'parks',
    },
    {
      value: 'users',
      label: 'users',
    },
  ];
  public users: User[] = [];

  public userCanCreateWorkOrder: boolean = true;

  public departmentManagerDepartments: Department[] = [];

  private selectedUsersIds: string[] = null;

  private readonly AVAILABILITIES_USER_ROLE = 'Technician';
  public filterMenu: PrgFilterMenuItem[] =
    this.arrayUtilityService.clone(CalendarFilters);

  private filterGroup: FilterGroup;
  constructor(
    private workOrdersService: WorkOrdersService,
    private entityTypeService: AbstractEntityTypeService,
    private utilityService: UtilityService,
    private notificationsService: NotificationsService,
    private translateService: TranslateService,
    private router: Router,
    private pageContextService: PageContextService,
    private userProfileService: UserProfileService,
    private departmentService: DepartmentService,
    private authGuard: AuthGuard,
    private authService: AbstractAuthService,
    private arrayUtilityService: ArrayUtilityService,
    private lookupTableService: AbstractLookupTableService,
    private resourceService: ResourceService
  ) {}

  public async ngOnInit(): Promise<void> {
    // need for load calendar bundle first (from https://github.com/fullcalendar/fullcalendar-angular/blob/main/app/src/app.component.ts)
    forwardRef(() => Calendar);
    this.calendarOptions.eventDidMount = (info) => {
      if (
        info.el.classList.contains('fc-event-dragging') ||
        info.el.classList.contains('fc-event-resizing')
      ) {
        return;
      } else {
        tippy(info.el, {
          content: info.event.extendedProps.tooltip,
          duration: 0,
          maxWidth: 250,
          delay: 200,
          allowHTML: true,
          trigger: 'mouseenter',
        });
      }
    };

    this.setUserPermissions();
    await this.setStaticOptionsForFilters();
    this.selectedEntityType =
      await this.entityTypeService.getAllEntityTypeDataByName(
        this.WORK_ORDERS_ENTITY_TYPE
      );

    const treeNodes = await this.entityTypeService.getEntityTypeElementsTree(
      this.WORK_ORDERS_ROOT_ENTITY_TYPE,
      null,
      null,
      0,
      true
    );

    treeNodes.forEach((treeNode) => (treeNode.selectable = false));

    this.treeNodes = treeNodes;

    this.pageContextService.subscribeVariable(
      'ListDragDropComponent',
      'selectedElements',
      async (value) => {
        if (this.calendarType == 'users') {
          if (value != null && value.length > 0) {
            this.selectedUsersIds = value.map((x) => x.id);
          } else {
            this.selectedUsersIds = [];
          }
          this.selectedNode = null;
          if (this.calendarOptions != null) {
            this.calendarOptions.selectable = this.selectedNode != null;
          }
          setTimeout(async () => {
            await this.getWorkOrdersAndUpdateCalendarEventsAsync();
          }, 50);
        }
      }
    );
  }

  private displayNewWorkOrderModal() {
    this.workOrderViewMode = ViewMode.Edit;
    this.displayWorkOrderModal = true;
  }

  private async displayEditWorkOrderModal(workOrderId: string): Promise<void> {
    this.workOrderViewMode = ViewMode.Read;
    this.selectedElement =
      await this.entityTypeService.getEntityTypeElementById(
        'WorkOrder',
        workOrderId
      );

    if (
      this.selectedElement?.id != null &&
      this.selectedElement?.workOrderStateId != 'workorderstates.cancel' &&
      this.selectedElement?.workOrderStateId != 'workorderstates.scheduled' &&
      this.selectedElement?.workOrderStateId != 'workorderstates.rejected'
    ) {
      this.workOrderId = this.selectedElement?.id;
      this.displayWorkOrderPanel = true;
    } else {
      this.displayWorkOrderPanel = false;
    }

    this.displayWorkOrderModal = true;
  }

  private processSelectedNodeResourceIds(
    treeNodes: TreeNode[],
    firstRun: boolean
  ): void {
    if (treeNodes == null || !treeNodes.length) {
      this.selectedNodeResourceIds = [];
      return;
    } else {
      if (firstRun) {
        this.selectedNodeResourceIds = [];
      }
      treeNodes.forEach((treeNode) => {
        if (treeNode.data.EntityType !== 'Client') {
          this.selectedNodeResourceIds.push(treeNode.data.Id);
        }
        if (treeNode.children != null && treeNode.children.length) {
          this.processSelectedNodeResourceIds(treeNode.children, false);
        }
      });
    }
  }

  private setCalendarEventsFromWorkOrders(): void {
    const updatedEvents = [];

    this.workOrders.forEach((workOrder) => {
      const backgroundColor = this.translateService.instant(
        'lookup-tables.workorderstates.items.' +
          workOrder.workOrderStateId.toLowerCase() +
          '.backgroundColor'
      );

      const startTime = this.getHoursAndMinutesFormattedTime(
        workOrder.plannedStartDate
      );
      const finishTime = this.getHoursAndMinutesFormattedTime(
        workOrder.plannedFinishDate
      );

      let isAllDay = false;

      if (startTime == '00:00' && finishTime == '23:59') {
        isAllDay = true;
      }

      const newEndDate = new Date(workOrder.plannedFinishDate);

      //TODO: CHECK ALLDAY, CHECK STARTTIME ENDTIME
      const newEvent: EventInput = {
        id: workOrder.id,
        allDay: isAllDay,
        className: workOrder.sentToClient ? 'sent-to-client' : '',
        title:
          workOrder != null
            ? workOrder?.name != null && workOrder?.name?.trim()?.length > 0
              ? workOrder.name
              : workOrder.workOrderAutoNumber != null
              ? workOrder.workOrderAutoNumber
              : ''
            : '',
        start: new Date(workOrder.plannedStartDate),
        end: isAllDay
          ? newEndDate.setDate(newEndDate.getDate() + 1)
          : newEndDate,
        backgroundColor: backgroundColor,
        borderColor: backgroundColor,
        tooltip:
          'OT: ' +
          (workOrder != null
            ? workOrder?.name != null && workOrder?.name?.trim()?.length > 0
              ? workOrder.name
              : workOrder.workOrderAutoNumber != null
              ? workOrder.workOrderAutoNumber
              : ''
            : ''),
      };
      if (
        this.departmentService.currentUserIsDepartmentManager() &&
        workOrder.createdBy == this.authService.getLoggedUser().id
      ) {
        newEvent.durationEditable = true;
        newEvent.editable = true;
      }

      if (
        workOrder.workOrderUsers != null &&
        workOrder.workOrderUsers.length > 0
      ) {
        const auxUsersAbb = [];
        const auxUsersForTooltip = [];

        workOrder.workOrderUsers.forEach((x) => {
          if (x.user?.name != null) {
            const auxNameSplit = x.user.name
              .split(' ')
              .map((word) => word[0])
              .join('');
            let auxNameSplitUpper = auxNameSplit?.toUpperCase();
            if (auxNameSplitUpper.length > 2) {
              auxNameSplitUpper = auxNameSplitUpper.slice(0, 2);
            }
            auxUsersAbb.push(auxNameSplitUpper);
            auxUsersForTooltip.push(x.user.name);
          }
        });

        if (auxUsersAbb.length > 0) {
          /*const workOrderDisplayName = startTime + ' - ' + finishTime + ' '+  newEvent.title*/
          const workOrderDisplayName = newEvent.title;

          newEvent.title = '';
          newEvent.tooltip = '';

          auxUsersAbb.forEach((abb, index) => {
            if (index == 0) {
              newEvent.title = newEvent.title.concat('(');
              newEvent.tooltip = newEvent.tooltip.concat('Técnicos: ');
            }
            newEvent.title = newEvent.title.concat(abb);
            newEvent.tooltip = newEvent.tooltip.concat(
              auxUsersForTooltip[index]
            );
            if (index < auxUsersAbb.length - 1) {
              newEvent.title = newEvent.title.concat(' / ');
              newEvent.tooltip = newEvent.tooltip.concat(', ');
            }
            if (index == auxUsersAbb.length - 1) {
              newEvent.title = newEvent.title.concat(')');
            }
          });
          newEvent.title = newEvent.title.concat(' ', workOrderDisplayName);
          newEvent.tooltip = newEvent.tooltip.concat(
            '',
            `<br> OT: ${workOrderDisplayName}`
          );
        }
      }

      if (workOrder['usersCount'] == null || workOrder['usersCount'] < 1) {
        newEvent.title += this.WORK_ORDER_WITHOUT_USERS_APPEND;
      }
      newEvent.tooltip = newEvent.tooltip.concat(
        '',
        `<br> Horário: ${startTime} - ${finishTime} `
      );
      if (workOrder.sentToClient == true) {
        newEvent.tooltip = newEvent.tooltip.concat(
          '',
          `<br>Cliente Notificado: ${new Date(
            workOrder.sentToClientDate
          ).toLocaleDateString()} `
        );
      }

      updatedEvents.push(newEvent);
    });

    this.calendarLegends = [];
    for (const [key, value] of Object.entries(
      this.workOrderStatesTranslation
    )) {
      this.calendarLegends.push(
        new CalendarLegend({
          backgroundColor: value['backgroundColor'],
          icon: value['icon'],
          label: value['label'],
          count: this.workOrders.filter(
            (w) => w.workOrderStateId.toLowerCase() === key
          ).length,
          data: {
            state: key,
          },
        })
      );
    }

    this.calendarOptions.events = updatedEvents;
  }

  private async getWorkOrdersAsync(): Promise<void> {
    this.processSelectedNodeResourceIds(
      this.selectedNode != null ? [this.selectedNode] : [],
      true
    );

    const endDate = this.setDateTimeToEndOfDay(
      new Date(this.calendarViewEndDate)
    );

    this.workOrders =
      await this.workOrdersService.getWorkOrdersByDateIntervalAsync(
        this.calendarViewStartDate,
        endDate,
        this.selectedNodeResourceIds,
        this.workOrderStatesSelected,
        this.selectedUsersIds,
        this.filterGroup
      );
  }

  private async getWorkOrdersAndUpdateCalendarEventsAsync(): Promise<void> {
    await this.getWorkOrdersAsync();
    this.setCalendarEventsFromWorkOrders();
    this.getOptionsForFiltersPresentOnWorkOrders();
  }

  private async updateWorkOrderToNewDatesFromCalendarEventAsync(
    arg: EventDropArg | EventResizeDoneArg
  ): Promise<void> {
    const workOrder = this.workOrders.find((w) => w.id === arg.event.id);

    if (this.departmentService.currentUserIsDepartmentManager()) {
      if (
        this.departmentManagerDepartments != null &&
        this.departmentManagerDepartments.length > 0
      ) {
        const department = this.departmentManagerDepartments.find(
          (x) => x.id == workOrder.departmentId
        );
        if (
          department == null ||
          (!department.canEditWorkOrderDates &&
            workOrder.createdBy != this.authService.getLoggedUser().id)
        ) {
          this.setCalendarEventsFromWorkOrders();
          await this.notificationsService.prgInfoDialog(
            'pages.work-orders.errors.not-have-edit-dates-permission'
          );
          return;
        }
      } else {
        this.setCalendarEventsFromWorkOrders();
        await this.notificationsService.prgInfoDialog(
          'pages.work-orders.errors.not-have-edit-dates-permission'
        );
        return;
      }
    }
    try {
      let d: Date;
      if (arg.event.end != null) {
        d = new Date(arg.event.end);
      } else if (arg.event.start != null) {
        d = new Date(arg.event.start);
        if (arg.event.allDay) {
          d.setDate(d.getDate() + 1);
        } else {
          d = this.setDateTimeToEndOfDay(d);
        }
      }

      if (arg.event.allDay) {
        // subtract 1 days
        d.setDate(d.getDate() - 1);
        d = this.setDateTimeToEndOfDay(d);
      }
      //d = this.setDateTimeToEndOfDay(d);

      const contractState =
        await this.resourceService.doesContractIncludeWorkOrderDates(
          new WorkOrder({
            ...workOrder,
            plannedStartDate: arg.event.start,
            plannedFinishDate: d,
          })
        );
      let messagePath = null;
      if (contractState == ContractDateRangeVerifierStates.WithoutContract) {
        messagePath = 'messages.create-work-order-without-contract';
      } else if (
        contractState == ContractDateRangeVerifierStates.InvalidContract
      ) {
        messagePath = 'messages.create-work-order-invalid-contract';
      }

      if (
        messagePath == null ||
        (await this.notificationsService.prgConfirmationService(messagePath))
      ) {
        const response =
          await this.workOrdersService.updateWorkOrderToNewDatesAsync(
            workOrder.id,
            arg.event.start,
            d
          );
        if (response != null) {
          Object.assign(workOrder, response);
        }
        this.getWorkOrdersAndUpdateCalendarEventsAsync();
      } else {
        this.setCalendarEventsFromWorkOrders();
      }
    } catch (error) {
      if (error?.error?.ErrorCode == 100001) {
        this.notificationsService.prgInfoDialog(
          'pages.work-orders.errors.not-have-user-availability'
        );
      } else if (error?.error?.ErrorCode == 403) {
        this.notificationsService.errorNotification(
          new PrgError({
            titleKey: '403 - Unauthorized',
            detailKey: error?.error?.ErrorMessage,
          })
        );
      }
      this.setCalendarEventsFromWorkOrders();
    }
  }

  public onWorkOrderModalClosed(): void {
    this.calendarApi.unselect();
    this.workOrderId = null;
    this.displayWorkOrderPanel = null;

    if (this.router.url != '/work-orders') {
      this.router.navigate(['/work-orders']);
    }
  }

  public async onSelectedItemChanged(): Promise<void> {
    if (this.calendarOptions != null) {
      this.calendarOptions.selectable = this.selectedNode != null;
    }
    if (!this.workOrderStatesSelected.length) {
      return;
    }
    await this.getWorkOrdersAndUpdateCalendarEventsAsync();
  }

  public async onCalendarDatesSetted(arg: DatesSetArg): Promise<void> {
    this.calendarApi = arg.view.calendar;
    this.calendarViewStartDate = arg.start;
    // remove single day from end date, as selection ends at 00:00:00 of the following day
    arg.end.setDate(arg.end.getDate() - 1);
    this.calendarViewEndDate = arg.end;
    if (!this.workOrderStatesSelected.length) {
      return;
    }
    await this.getWorkOrdersAndUpdateCalendarEventsAsync();
  }

  public onCalendarDatesUnSelected(): void {
    this.selectedDates = [];
  }

  public async onCalendarDatesSelected(arg: DateSelectArg): Promise<void> {
    if (
      !this.userCanCreateWorkOrderOnASpecificPark(this.selectedNode.data.Id)
    ) {
      this.calendarApi.unselect();
      return;
    }
    // remove single day from end date, as selection ends at 00:00:00 of the following day
    arg.end.setDate(arg.end.getDate() - 1);

    this.selectedDates = [new Date(arg.start), new Date(arg.end)];

    this.selectedElement = new WorkOrder({
      plannedStartDate: this.selectedDates[0],
      plannedFinishDate: this.selectedDates[this.selectedDates.length - 1],
      resourceId: this.selectedNode.data.Id,
      isRecurring: false,
      recurrence: null,
    });
    this.displayWorkOrderModal = false;
    if (this.selectedDates.length > 0) {
      const finishDate = this.setDateTimeToEndOfDay(
        this.selectedDates[this.selectedDates.length - 1]
      );
      this.selectedElement.plannedFinishDate = finishDate;

      this.displayNewWorkOrderModal();
    }
  }

  public async onCalendarEventDrop(arg: EventDropArg): Promise<void> {
    // await this.updateWorkOrderFromCalendarEventAsync(arg);

    await this.updateWorkOrderToNewDatesFromCalendarEventAsync(arg);
  }

  public async onCalendarEventResizeStopped(
    arg: EventResizeDoneArg
  ): Promise<void> {
    await this.updateWorkOrderToNewDatesFromCalendarEventAsync(arg);

    //await this.updateWorkOrderFromCalendarEventAsync(arg);
  }

  public async onCalendarEventClicked(arg: EventClickArg) {
    await this.displayEditWorkOrderModal(arg.event.id);
  }

  public onAddButtonClicked() {
    this.selectedElement = new WorkOrder();
    if (this.selectedNode != null) {
      if (
        !this.userCanCreateWorkOrderOnASpecificPark(
          this.selectedNode.data['Id']
        )
      ) {
        return;
      }
      this.selectedElement.resourceId = this.selectedNode.data['Id'];
    }
    this.selectedElement['isRecurring'] = false;
    this.selectedElement['recurrence'] = null;

    this.displayNewWorkOrderModal();
  }

  public onDragStarted(event: DragEvent): void {
    const el = event.srcElement as HTMLElement;
    if (el == null) {
      return;
    }

    const id = el.dataset.id;

    if (id == null) {
      return;
    }

    this.treeNodes.forEach((n) => {
      const child =
        n.children != null ? n.children.find((c) => c.data['Id'] === id) : null;
      if (child != null) {
        this.selectedNode = child;
      }
    });
  }

  /**
   *
   * @param event this function put an backgroung on calendar cell hovered
   * @returns
   */
  public onDragged(event: DragEvent): void {
    const elements = document.elementsFromPoint(event.x, event.y);
    const el = elements.find((e) => e.classList.contains('fc-day'));
    const el2 = elements.find((e) =>
      e.classList.contains('fc-timegrid-slot-lane')
    );

    if (el2 == null) {
      this.removeHoveredClass();

      if (el == null) {
        return;
      }
      if (!el.classList.contains('fc-hovered')) {
        el.classList.add('fc-hovered');
      }
    } else {
      this.removeHoveredClass();
      if (!el2.classList.contains('fc-hovered')) {
        el2.classList.add('fc-hovered');
      }
    }
  }

  public removeHoveredClass() {
    const otherEls = document.querySelectorAll('.fc-day.fc-hovered');
    const otherEls2 = document.querySelectorAll(
      '.fc-timegrid-slot-lane.fc-hovered'
    );
    if (otherEls != null && otherEls.length) {
      for (let i = 0; i < otherEls.length; i++) {
        otherEls[i].classList.remove('fc-hovered');
      }
    }
    if (otherEls2 != null && otherEls2.length) {
      for (let i = 0; i < otherEls2.length; i++) {
        otherEls2[i].classList.remove('fc-hovered');
      }
    }
  }

  public async onNodeDropped(event: DragEvent): Promise<void> {
    const elements = document.elementsFromPoint(event.x, event.y);

    const el = elements.find((e) => e.classList.contains('fc-day'));
    const el2 = elements.find((e) => e.classList.contains('fc-timegrid-slot'));

    if (el == null) {
      return;
    }
    if (
      !this.userCanCreateWorkOrderOnASpecificPark(this.selectedNode.data.Id)
    ) {
      this.removeHoveredClass();
      return;
    }

    let time = null;

    const date = (el as HTMLElement).dataset.date;
    if (date == null) {
      return;
    }

    if (el2) {
      time = (el2 as HTMLElement).dataset.time;
    }

    let plannedStartDate = null;
    let plannedFinishDate = null;

    if (time != null) {
      const initialDateTime = new Date(
        this.utilityService.getIsoStringInLocalTime(date + 'T' + time)
      );

      const finalDateTime = dayjs(initialDateTime).add(1, 'hours').toDate();

      plannedStartDate = new Date(
        this.utilityService.getIsoStringInLocalTime(initialDateTime)
      );
      plannedFinishDate = new Date(
        this.utilityService.getIsoStringInLocalTime(finalDateTime)
      );
    } else {
      plannedStartDate = new Date(
        this.utilityService.getIsoStringInLocalTime(date)
      );
      plannedFinishDate = this.setDateTimeToEndOfDay(
        new Date(this.utilityService.getIsoStringInLocalTime(date))
      );
    }

    this.selectedElement = new WorkOrder();
    this.selectedElement.plannedStartDate = plannedStartDate;
    this.selectedElement.plannedFinishDate = plannedFinishDate;
    this.selectedElement.resourceId = this.selectedNode.data['Id'];
    this.selectedElement['isRecurring'] = false;
    this.selectedElement['recurrence'] = null;

    /* await this.getAndSetAvailableUsersForDates(plannedStartDate, plannedFinishDate);*/
    this.removeHoveredClass();
    this.displayNewWorkOrderModal();
  }

  public async onExecutedAction(action: ExecutedAction) {
    if (
      action.baseAction.key === 'create' ||
      action.baseAction.key === 'update' ||
      action.baseAction.key === BaseActionKey.Delete ||
      action.baseAction.key === 'sendtoclient' ||
      action.baseAction.key === 'rejectworkorder'
    ) {
      this.workOrderViewMode = ViewMode.Read;
      await this.getWorkOrdersAndUpdateCalendarEventsAsync();
    }
    this.displayWorkOrderModal = false;
  }

  async openWorkOrderPanel(id: string, resourceId: string): Promise<void> {
    await this.router.navigate(['work-orders', id, 'park'], {
      state: {
        parkId: resourceId,
      },
    });
  }

  public async toggleWorkorderState(state: string) {
    const stateIndexOf = this.workOrderStatesSelected.indexOf(state);
    if (stateIndexOf == -1) {
      this.workOrderStatesSelected.push(state);
    } else {
      this.workOrderStatesSelected.splice(stateIndexOf, 1);
    }

    if (!this.workOrderStatesSelected.length) {
      this.workOrders = [];
      this.setCalendarEventsFromWorkOrders();
      return;
    }
    await this.getWorkOrdersAndUpdateCalendarEventsAsync();
  }

  private setDateTimeToEndOfDay(date: Date): Date {
    return new Date(date.setHours(23, 59, 0));
  }

  onClickCreateWorkOrderFromAnother(event: WorkOrder): void {
    this.displayWorkOrderModal = false;

    setTimeout(() => {
      this.selectedElement = new WorkOrder({
        genericRelation: new GenericRelation({
          sourceEntityType: 'WorkOrder',
          targetEntityType: 'WorkOrder',
          sourceId: event.id,
        }),
        resourceId: event.resourceId,
        name: event.name,
        description: event.description,
        workOrderTypeId: event.workOrderTypeId,
        recurrence: null,
        isRecurring: false,
      });
      this.displayNewWorkOrderModal();
    }, 500);
  }

  async onDeleteWorkOrder(): Promise<void> {
    this.displayWorkOrderModal = false;
    await this.getWorkOrdersAndUpdateCalendarEventsAsync();
  }

  async onChangeCalendarType(event: {
    originalEvent: PointerEvent;
    value: string;
  }): Promise<void> {
    if (
      event.value == 'users' &&
      (this.users == null || this.users.length == 0)
    ) {
      this.users = await this.userProfileService.getUsersByRoleAsync(
        this.AVAILABILITIES_USER_ROLE
      );
    } else {
      setTimeout(async () => {
        await this.getWorkOrdersAndUpdateCalendarEventsAsync();
      }, 50);
    }
  }

  public getHoursAndMinutesFormattedTime(date: string | Date) {
    const dateHours = new Date(date).getHours();
    const formattedHours = dateHours < 10 ? '0' + dateHours : dateHours;
    const dateMinutes = new Date(date).getMinutes();
    const formattedMinutes = dateMinutes < 10 ? '0' + dateMinutes : dateMinutes;
    return formattedHours + ':' + formattedMinutes;
  }

  public setUserPermissions() {
    if (!this.authGuard.isGranted('update', 'workorder')) {
      this.calendarOptions.eventDurationEditable = false;
      this.calendarOptions.editable = false;
    }
    if (!this.authGuard.isGranted('create', 'workorder')) {
      this.userCanCreateWorkOrder = false;
    }

    if (this.departmentService.currentUserIsDepartmentManager()) {
      this.departmentManagerDepartments =
        this.departmentService.getUserDepartmentsValue();
      if (this.departmentManagerDepartments != null) {
        this.userCanCreateWorkOrder =
          this.authGuard.isGranted('create', 'workorder') &&
          this.departmentManagerDepartments.some(
            (x) => x.canCreateWorkOrder == true
          );
        const aux =
          this.authGuard.isGranted('update', 'workorder') &&
          this.departmentManagerDepartments.some(
            (x) => x.canEditWorkOrderDates == true
          );
        this.calendarOptions.eventDurationEditable = aux;
        this.calendarOptions.editable = aux;
      } else {
        this.userCanCreateWorkOrder = false;
        this.calendarOptions.eventDurationEditable = false;
        this.calendarOptions.editable = false;
      }
    }
  }
  private userCanCreateWorkOrderOnASpecificPark(resourceId: string) {
    if (this.departmentService.currentUserIsDepartmentManager()) {
      const departments = this.departmentManagerDepartments.filter((x) =>
        x.resources.find((x) => x.id == resourceId)
      );
      if (!departments.some((x) => x.canCreateWorkOrder == true)) {
        this.notificationsService.prgInfoDialog(
          'pages.work-orders.errors.not-have-create-permission'
        );
        return false;
      }
    }
    return true;
  }

  onOutputFilters(event: Filter[]): void {
    if (this.filterGroup == null && (event == null || event.length == 0)) {
      return;
    }

    if (event == null || event.length == 0) {
      this.filterGroup = null;
    } else {
      this.filterGroup = new FilterGroup({
        filterCollections: this.arrayUtilityService.clone(event),
        orderCollection: [],
      });
    }

    this.getWorkOrdersAndUpdateCalendarEventsAsync();
  }

  private getOptionsForFiltersPresentOnWorkOrders(): void {
    //Tag Options
    let tags = [];
    this.workOrders.map((x) => {
      if (x.tags && x.tags.length > 0) {
        x.tags.forEach((y) => {
          tags.push({
            id: y.id,
            label: y.name,
          });
        });
      }
    });
    if (tags.length > 0) {
      tags = [...new Map(tags.map((m) => [m.id, m])).values()];
      const filterTags = this.filterMenu.find(
        (x) => x.propertyName == 'Tags[Id]'
      );
      tags.forEach((x) => {
        const aux = filterTags.items.find((y) => y.value == x.id);
        if (aux == null) {
          filterTags.items.push(
            new PrgFilterMenuItemChild({
              value: x.id,
              label: x.label,
              filterOperation: FilterOperations.EqualTo,
              translatedItem: false,
            })
          );
        }
      });
      filterTags.items.sort((a, b) => {
        return a.label.localeCompare(b.label);
      });
    }
  }

  private async setStaticOptionsForFilters() {
    const workOrderTypes =
      await this.lookupTableService.getLookupTableItemsByLookupTableIdAsync(
        'workordertypes'
      );
    if (workOrderTypes != null && workOrderTypes.length > 0) {
      const filterWorkOrderTypeId = this.filterMenu.find(
        (x) => x.propertyName == 'WorkOrderTypeId'
      );
      filterWorkOrderTypeId.items = [];
      workOrderTypes.forEach((x) => {
        filterWorkOrderTypeId.items.push(
          new PrgFilterMenuItemChild({
            value: x.id,
            label: x.label,
            filterOperation: FilterOperations.EqualTo,
            translatedItem: false,
          })
        );
      });
    }
  }
}
